import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './NavMenu.css';

const NavMenu = () => {
  const [menuActive, setMenuActive] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Hide the menu whenever the location changes
    setMenuActive(false);
  }, [location]);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const isActive = (path) => {
    return location.pathname === path ? 'selectedNav' : '';
  };

  return (
    <nav className="nav-menu">
      <div className="menu-toggle" onClick={toggleMenu}>
        ☰ Menu
      </div>
      <ul className={`menu-items ${menuActive ? 'active' : ''}`}>
        <li>
          <Link to="/" className={isActive("/")}>
            Result Check
          </Link>
        </li>
        <li>
          <Link to="/notification" className={isActive("/notification")}>
            Notices
          </Link>
        </li>
        <li>
          <Link to="/hall" className={isActive("/hall")}>
            Hall Result
          </Link>
        </li>
        <li>
          <Link to="/department" className={isActive("/department")}>
            Department Result
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default NavMenu;
