import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import NotificationScreen from "./pages/Notice/NotificationScreen";
import CheckResult from "./pages/Search/CheckResult";
import Footer from "./components/Footer";
import ResultPage from "./pages/Result/ResultPage";
import './App.css';

const App = () => {
  return (
    <Router>
      <div className="container">
        <header className="header">
          <Header />
        </header>
        <main className="main">
          <Routes>
            <Route path="/" element={<CheckResult />} />
            <Route path="/notification" element={<NotificationScreen />} />
            <Route path="/results" element={<ResultPage />} />
          </Routes>
        </main>
        <footer className="footer">
          <Footer />
        </footer>
      </div>
    </Router>
  );
};

export default App;
