import React from "react";
import "./header.css";
import NavMenu from './NavMenu/NavMenu';

const Header = () => {
  return (
    <div className="">
      <div className="header">
        <div className="logo-and-title">
          <img
            src="https://result.du.ac.bd/assets/rsz_12dhaka-university.png"
            alt="University of Dhaka Logo"
            className="logo"
          />
          <div className="headerTexts">
            <h2>University of Dhaka</h2>
            <h3>Result Archive</h3>
          </div>
        </div>
      </div>
      <NavMenu/>
    </div>
  );
};

export default Header;
