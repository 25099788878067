import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./resultPage.css"; // Import your CSS file for styling
import { RiCheckDoubleFill } from "react-icons/ri";
import { GoAlertFill } from "react-icons/go";

const ResultPage = () => {
  const location = useLocation();
  const { results } = location.state || {};
  const { courses } = location.state || {};
  const navigate = useNavigate();

  if (!results) {
    return <div>No result data available.</div>;
  }

  function format2Digit(number) {
    // Convert the number to a string
    let numStr = number.toString();

    // Find the position of the decimal point
    let dotIndex = numStr.indexOf(".");

    // If there's no decimal point, add ".00" to the end
    if (dotIndex === -1) {
      return numStr + ".00";
    }

    // Extract the integer part and the decimal part
    let integerPart = numStr.substring(0, dotIndex);
    let decimalPart = numStr.substring(dotIndex + 1);

    // Ensure the decimal part has at least 2 digits
    if (decimalPart.length === 1) {
      return integerPart + "." + decimalPart + "0";
    } else if (decimalPart.length >= 2) {
      return integerPart + "." + decimalPart.substring(0, 2);
    }
  }

  return (
    <div className="result-page">
      <div className="card">
        <div className="headerPart">
          <h3>
            {results[0].subject_name} - {results[0].exam_title}
          </h3>
          <p>
            Exam Held in: {results[0].exam_held_in}
          </p>
          <p>
            Memo No: {results[0].result_memo}
          </p>
        </div>

        <div className="main-content">
          <div className="student_info">
            <table>
              <tbody>
                <tr>
                  <td>Student Name</td>
                  <td>
                    {results[0].student_name}
                  </td>
                </tr>
                <tr>
                  <td>Hall Name</td>
                  <td>
                    {results[0].hall_name}
                  </td>
                </tr>
                <tr>
                  <td>Subject Name</td>
                  <td>
                    {results[0].subject_name}
                  </td>
                </tr>
                <tr>
                  <td>Registration No</td>
                  <td>
                    {results[0].api_reg_num}
                  </td>
                </tr>
                <tr>
                  <td>Exam Roll</td>
                  <td>
                    {results[0].exam_roll}
                  </td>
                </tr>
                <tr>
                  <td>Session</td>
                  <td>
                    {results[0].session_name}
                  </td>
                </tr>

                {results[0].exam_semester === "1" &&
                  <tr>
                    <td>
                      {" "}1<sup>st</sup> {results[0].type} GPA
                    </td>
                    <td>
                      {results[0].first_gpa}
                    </td>
                  </tr>}

                {results[0].exam_semester === "2" &&
                  <tr>
                    <td>
                      2<sup>nd</sup> {results[0].type} GPA
                    </td>
                    <td>
                      {results[0].second_gpa}
                    </td>
                  </tr>}

                {results[0].exam_semester === "3" &&
                  <tr>
                    <td>
                      3<sup>rd</sup> {results[0].type} GPA
                    </td>
                    <td>
                      {results[0].three_gpa}
                    </td>
                  </tr>}

                {results[0].exam_semester === "4" &&
                  <tr>
                    <td>
                      4<sup>th</sup> {results[0].type} GPA
                    </td>
                    <td>
                      {results[0].four_gpa}
                    </td>
                  </tr>}

                {results[0].exam_semester === "5" &&
                  <tr>
                    <td>
                      5<sup>th</sup> {results[0].type} GPA
                    </td>
                    <td>
                      {results[0].five_gpa}
                    </td>
                  </tr>}

                {results[0].exam_semester === "6" &&
                  <tr>
                    <td>
                      6<sup>th</sup> {results[0].type} GPA
                    </td>
                    <td>
                      {results[0].six_gpa}
                    </td>
                  </tr>}

                {results[0].exam_semester === "7" &&
                  <tr>
                    <td>
                      7<sup>th</sup> {results[0].type} GPA
                    </td>
                    <td>
                      {results[0].seven_gpa}
                    </td>
                  </tr>}

                {results[0].exam_semester === "8" &&
                  <tr>
                    <td>
                      8<sup>th</sup> {results[0].type} GPA
                    </td>
                    <td>
                      {results[0].eight_gpa}
                    </td>
                  </tr>}
                {results[0].final_cgpa &&
                  <tr>
                    <td>CGPA</td>
                    <td>
                      {results[0].final_cgpa}
                    </td>
                  </tr>}
                <tr>
                  <td>Result</td>
                  <td>
                    {results[0].result}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Second Excel Table - Course Details */}
          <div className="course-details">
            <table>
              <thead>
                <tr>
                  <th>Course Code</th>
                  <th>Course Title</th>
                  <th>Grade Point</th>
                  <th>Letter Grade</th>
                  <th>Credit</th>
                </tr>
              </thead>
              <tbody>
                {courses
                  ? courses.map((courses, index) =>
                      <tr key={index}>
                        <td style={{ textAlign: "center" }}>
                          {courses.course_code}
                        </td>
                        <td style={{ marginLeft: 60, textAlign: "left" }}>
                          {courses.course_title}
                        </td>

                        <td style={{ textAlign: "center" }}>
                          {format2Digit(courses.grade_point)}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {courses.letter_grade}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {courses.course_credit}
                        </td>
                      </tr>
                    )
                  : <tr>
                      <td colSpan="5">No course available</td>
                    </tr>}
              </tbody>
            </table>
            <p style={{ textAlign: "left" }}>
              {" "} <RiCheckDoubleFill /> Result Publication Date:{" "}
              {results[0].result_pub_date.split("-").reverse().join("-")}
            </p>
          </div>

          {results[0].remarks &&
            <div className="remarks">
              <p style={{ color: "black", textAlign: "left" }}>
                <GoAlertFill /> Remarks: {results[0].remarks}
              </p>
            </div>}

          <div className="bangla_text">
            <p>
              {" "}<RiCheckDoubleFill /> বি.দ্র. পরীক্ষার ফল প্রকাশের তারিখ হতে
              ০২ (দুই) মাসের মধ্যে ভর্তি কার্যক্রম সম্পন্ন করতে হবে।{" "}
            </p>
          </div>

          {/* Buttons */}
          <div className="buttons">
            <button className="button" style={{ backgroundColor: "#33b0e0" }}>
              PDF Download
            </button>
            <button className="button" style={{ backgroundColor: "#4489e4" }}>
              Print
            </button>
            <button
              className="button"
              style={{ backgroundColor: "#716cb0" }}
              onClick={() => navigate("/")}
            >
              Search Again
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultPage;
