import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import "./checkResult.css"; // Assuming you have a CSS file for styling
import Swal from "sweetalert2";

const CheckResult = () => {
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [reg, setReg] = useState("2016118696");
  const [roll, setRoll] = useState("6601");
  const [exams, setExams] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  const api = "https://v2.result.du.ac.bd/WebApi";

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(api + "/getDepartments");
        if (response.data.status === 200) {
          const departmentsData = response?.data?.result.map((department) => ({
            value: department.subject_id,
            label: department.subject_name,
          }));
          setDepartments(departmentsData);
        } else {
          console.error("Failed to fetch departments:", response.data.message);
          Swal.fire({
            icon: "error",
            title: response?.data?.message || "Oops...",
            footer: response?.data?.subheading || "Something went wrong!",
          });
        }
      } catch (err) {
        console.error("API Error:", err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          footer: "Something went wrong!",
        });
      }
    };

    fetchDepartments();
  }, []);

  const fetchExams = async (subject) => {
    try {
      const response = await axios.get(api + "/getExaminations", {
        params: {
          subject_id: subject.value,
        },
      });

      if (response.data.status === 200) {
        setExams(response.data.result);
      } else {
        console.error("Failed to fetch examinations:", response.data.message);
        Swal.fire({
          icon: "error",
          title: response?.data?.message || "Oops...",
          footer: response?.data?.subheading || "Something went wrong!",
        });
      }
    } catch (err) {
      console.error("API Error:", err);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        footer: "Something went wrong!",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const response = await axios.get(api + "/getResult", {
        params: {
          subject_id: selectedDepartment.value,
          exam_id: selectedSubject,
          reg: reg,
          roll: roll,
        },
      });

      if (response?.data?.status === 200) {
        navigate("/results", {
          state: {
            results: response?.data?.result,
            courses: response?.data?.courses,
          },
        }); // Navigate to the results page
      } else {
        console.error("Failed to fetch result:", response.data.message);
        Swal.fire({
          icon: "error",
          title: response?.data?.message || "Oops...",
          footer: response?.data?.subheading || "Something went wrong!",
        });
      }
    } catch (err) {
      console.error("API Error:", err);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer:
          "Please check your selected subject, examination, registration & roll number.",
      });
    }
    setLoading(false)
  };

  const getExams = async (subject) => {
    setSelectedDepartment(subject);
    await fetchExams(subject);
  };

  return (
    <div className="card border-none">

      <form className="form" onSubmit={handleSubmit}>
        <div className="fulForm">
          <div className="form-group">
            <label>Select Subject:</label>
            <Select
              value={selectedDepartment}
              onChange={getExams}
              options={departments}
              placeholder="Search and select subject..."
              isClearable
              isSearchable
              styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }} // Ensure dropdown is above other elements
            />
          </div>
          <div className="form-group">
            <label>Select Exam:</label>
            <select
              className="form-control"
              name="Select Exam"
              onChange={(e) => setSelectedSubject(e.target.value)}
              value={selectedSubject}
              disabled={!selectedDepartment} // Disable if no department selected
              required
            >
              <option value="">Select exam</option>
              {exams?.map((exam) => (
                <option key={exam.exam_id} value={exam.exam_id}>
                  {exam.exam_title}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Registration Number:</label>
            <input
              type="number"
              placeholder="1243545768"
              className="form-control"
              value={reg}
              onChange={(e) => setReg(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Exam Roll:</label>
            <input
              type="number"
              placeholder="1243"
              className="form-control"
              value={roll}
              onChange={(e) => setRoll(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="button-group">
          <button type="submit" className={loading ? "check-button disabled" : "check-button"}>
             
            {loading ? "Searching Result" : "Search Result"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CheckResult;
