import React from "react";
import "./footer.css"; // Import your CSS file for styling

const Footer = () => {
  return (
    <footer className="footer">
      <p className="footer-content">
        2024 - 2025 © Result Archive | University of Dhaka
      </p>
      <p className="footer-content">
        Developed and Maintained By Office of the Controller of Examinations
      </p>
    </footer>
  );
};

export default Footer;
