import React, { useEffect, useState } from 'react';
import './latestNotices.css';
import { FaBell, FaCalendarAlt } from 'react-icons/fa';
import axios from 'axios';

const LatestNotices = () => {
  const [notices, setNotices] = useState([]);

  useEffect(() => {
    const fetchNotices = async () => {
      try {
        const response = await axios.get('https://v2.result.du.ac.bd/webApi/getNotices');
        console.log("🚀 ~ fetchNotices ~ response:", response)
        if (response.data && Array.isArray(response.data.notices)) {
          const formattedNotices = response.data.notices.map(notice => {
            const dateTime = new Date(notice.result_published_on.replace(', ', 'T'));
            const fullDateTime = `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
            
            return {
              id: notice.exam_id,
              title: `${notice.subject_name} - ${notice.exam_title}`,
              dateTime: fullDateTime, // Use full formatted date and time here
            };
          });
          setNotices(formattedNotices);
        } else {
          console.error('Invalid data format from API:', response.data);
        }
      } catch (error) {
        console.error('Error fetching notices:', error);
      }
    };

    fetchNotices();
  }, []);

  return (
    <div className="latest-notices-container">
      {notices && notices.length > 0 ? (<ul className="notices-list">
        {notices.map(notice => (
          <li key={notice.id} className="notice-item">
            <div className="notice-icon">
              <FaBell />
            </div>
            <div className="notice-content">
              <span>{notice.title}</span>
              <div className="notice-time">
                <FaCalendarAlt/>
                <span>  {notice.dateTime}</span>
              </div>
            </div>
          </li>
        ))}
      </ul>) : (<p>Loading Notices...</p>)}
    </div>
  );
};

export default LatestNotices;
